import React from "react"
import Layout from "../components/Layout"
import * as styles from "../assets/css/impressum.module.css"

const About = () => {
  return (
    <Layout>
      <div className={styles.impressumHeader}>Impressum & Datenschutz</div>

      <div className={styles.impressumContentContainer}>
        <div className={styles.impressumText1}>
          <h2>Dr. Dieter Krametter</h2>
          <ul>
            <li>Facharzt für Neurologie</li>
            <li>Arzt für Allgemeinmedizin</li>
            <li>Alle Kassen und Privat</li>
          </ul>
          <ul>
            <li>Hans-Sutter-Gasse 3</li>
            <li>8160 Weiz</li>
          </ul>
          <div className={styles.impressumText1Spacer}></div>
          <p>
            Mitglied der Ärztekammer für Steiermark Facharztdiplom erworben in
            Österreich
          </p>
          <p>
            Tätigkeit unterliegt dem Ärztegesetz 1998 (siehe{" "}
            <a href="https://www.ris.bka.gv.at/bundesrecht">
              www.ris.bka.gv.at/bundesrecht
            </a>
            )
          </p>
        </div>
      </div>
      <div className={styles.impressumText1Spacer}></div>

      <div className={styles.impressumText}>
        <p>
          <b>Haftungsausschluss</b>
        </p>
        <p>1. Inhalt des Onlineangebotes</p>
        <p>
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
          Vollständigkeit oder Qualität der bereitgestellten Informationen.
          Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
          oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
          der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollständiger Informationen verursacht wurden, sind grundsätzlich
          ausgeschlossen, sofern seitens des Autors kein nachweislich
          vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle
          Angebote sind freibleibend und unverbindlich. Der Autor behält es sich
          ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
      </div>
      <div className={styles.impressumText}>
        <p>2. Verweise und Links</p>
        <p>
          Bei direkten oder indirekten Verweisen auf fremde Webseiten
          ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
          Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es
          ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
          ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
          Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
          aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft
          der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss.
          Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten
          aller verlinkten /verknüpften Seiten, die nach der Linksetzung
          verändert wurden. Diese Feststellung gilt für alle innerhalb des
          eigenen Internetangebotes gesetzten Links und Verweise sowie für
          Fremdeinträge in vom Autor eingerichteten Gästebüchern,
          Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen
          anderen Formen von Datenbanken, auf deren Inhalt externe
          Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die aus der
          Nutzung oder Nichtnutzung solcherart dargebotener Informationen
          entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen
          wurde, nicht derjenige, der über Links auf die jeweilige
          Veröffentlichung lediglich verweist.
        </p>
      </div>
      <div className={styles.impressumText}>
        <p>3. Urheber- und Kennzeichenrecht</p>
        <p>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
          beachten, von ihm selbst erstellte Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb
          des Internetangebotes genannten und gegebenenfalls durch Dritte
          geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
          Bestimmungen des jeweils gültigen Kennzeichenrechts und den
          Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund
          der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
          nicht durch Rechte Dritter geschützt sind! Das Copyright für
          veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim
          Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher
          Grafiken, Tondokumente, Videosequenzen und Texte in anderen
          elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
          Zustimmung des Autors nicht gestattet.
        </p>
      </div>
      <div className={styles.impressumText}>
        <p>4. Datenschutz</p>
        <p>
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
          persönlicher oder geschäftlicher Daten (Email-Adressen, Namen,
          Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
          des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme
          und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich
          und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe
          anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der
          im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten
          Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie
          Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderten Informationen ist nicht gestattet. Rechtliche Schritte
          gegen die Versender von sogenannten Spam-Mails bei Verstößen gegen
          dieses Verbot sind ausdrücklich vorbehalten.
        </p>
      </div>
      <div className={styles.impressumText}>
        <p>5. Rechtswirksamkeit dieses Haftungsausschlusses</p>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
          die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
          davon unberührt.
        </p>
      </div>
      <div className={styles.impressumText}>
        <p>6. Schreibung - geschlechterspezifische Differenzierung</p>
        <p>
          Auf männlich-weibliche Doppelformen wird zur besseren Lesbarkeit
          verzichtet; die weibliche Form ist jeweils mitgemeint.
        </p>
      </div>

      <div className={styles.impressumContentBottom}>
        <div className={styles.impressumTextBottom}>
          <p>
            Bildquellen: Dr. Krametter, pixellio.de, stock.xhcng, pixabay.com,
            flaticon.com
          </p>
          <div className={styles.impressumText1Spacer}></div>
          <p>
            Folgende Vereinigungen / Vereine / Organistationen werden von Dr.
            Krametter regelmäßig finanziell unterstützt:
          </p>
          <p>
            <a href="https://www.lichtfuerkinder.at/">Licht für Kinder</a>
          </p>
          <p>
            <a href="https://www.lebenshilfe-weiz.at/">Lebenshilfe Weiz</a>
          </p>
          <p>
            <a href="https://www.rotary.org/de">Rotary International</a>
          </p>
          <p>
            <a href="https://www.greenpeace.org/eu-unit/">Greenpeace EU</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default About
